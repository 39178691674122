import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PostSubHeader from "../components/postSubHeader"
import Seo from "../components/seo"

export default function Template({ data }) {
  const { markdownRemark: post } = data


  return (
    <Layout>
    <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
    />
    <Helmet title={`${post.frontmatter.title}`} />
    <div className="blog-post-container">
      <div className="blog-post">
        <PostSubHeader
          category={post.frontmatter.category}
          date={post.frontmatter.date}
          tags={post.frontmatter.tags}
        />
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt(pruneLength: 100)
      html
      frontmatter {
        category
        date
        path
        tags
        title
      }
    }
  }
`
